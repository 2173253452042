@charset "UTF-8";
body {
  @apply bg-white;
  font-family: "Barlow", sans-serif;
}

.bigBkg {
  position: absolute;
  width: 100%;
  height: 100%;
}
.bigBkg span {
  @apply text-black text-center text-5xl max-w-md mx-auto block -mt-64 bg-secondary py-4 px-8 mb-48 z-20;
  font-family: "Barlow", sans-serif;
}

.logoHeader  {
  background: #000;
  border: 1px solid #000;
  @apply w-40;
}

.hero-home {
  position: relative;
  z-index: 10;
}

.boxHome-01 {
  background: #02081f url("../img/box-home/01.jpg") no-repeat center;
  background-size: cover;
  -webkit-transition: background-image 0.6s ease-in-out;
  transition: background-image 0.6s ease-in-out;
}
.boxHome-01:hover {
  background: #02081f url("../img/box-home/01_hover.jpg") no-repeat center;
  background-size: cover;
  -webkit-transition: background-image 0.6s ease-in-out;
  transition: background-image 0.6s ease-in-out;
}

.boxHome-02 {
  background: #02081f url("../img/box-home/02.jpg") no-repeat center;
  background-size: cover;
  -webkit-transition: background-image 0.6s ease-in-out;
  transition: background-image 0.6s ease-in-out;
}
.boxHome-02:hover {
  background: #02081f url("../img/box-home/02_hover.jpg") no-repeat center;
  background-size: cover;
  -webkit-transition: background-image 0.6s ease-in-out;
  transition: background-image 0.6s ease-in-out;
}

.boxHome-03 {
  background: #02081f url("../img/box-home/03.jpg") no-repeat center;
  background-size: cover;
  -webkit-transition: background-image 0.6s ease-in-out;
  transition: background-image 0.6s ease-in-out;
  position: relative;
}
.boxHome-03:hover {
  background: #02081f url("../img/box-home/03_hoverCS2.jpg") no-repeat center;
  background-size: cover;
  -webkit-transition: background-image 0.6s ease-in-out;
  transition: background-image 0.6s ease-in-out;
}
#menu {
  background: #051224;
  color: #fff;
}
#menu .mm-navbar .mm-title {
  visibility: hidden;
}
#menu .mm-listview li {
  font-size: 20px;
  @apply border-b border-gray-700 border-r-0;
}
#menu .mm-listview li a {
  @apply text-secondary pl-6;
  line-height: 30px;
}
#menu .mm-listview li span {
  color: #fff;
  line-height: 30px;
}

.mm-slideout {
  z-index: auto;
}

.nav-top__menu li:last-child {
  @apply border-0;
}
.nav-top__menu li a {
  @apply px-1 mx-1 text-primary transition-colors duration-200 font-bold;
}
.nav-top__menu li a:hover {
  @apply text-secondary no-underline;
}

.main .breadcrumb {
  background-color: transparent;
  text-transform: uppercase;
  padding: 15px 10px 5px 10px;
  font-style: italic;
  border-bottom: solid 1px #ddd;
  border-radius: 0;
  margin-bottom: 10px;
  list-style: none;
}
.main .breadcrumb li {
  display: inline-block;
  color: red;
}
.main .breadcrumb li a {
  color: inherit;
}
.main .breadcrumb > li + li::before {
  padding: 0 5px;
  color: #ccc;
  content: "/ ";
}
.main .breadcrumb .last {
  color: #a29da5;
}
.main .region-content {
  min-height: 300px;
}
.main .region-content .ticket-process-panel {
  /* Scelta in pianta */
}
.main .region-content .ticket-process-panel .court__message {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
}
.main .region-content .ticket-process-panel .court-map_2d {
  margin: 1em 0;
}
.main .region-content .ticket-process-panel .court-map_2d .svg {
  max-width: 100%;
  max-height: 600px;
  margin: auto;
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .main .region-content .ticket-process-panel .court-map_2d {
    /* IE10+ CSS */
  }
  .main .region-content .ticket-process-panel .court-map_2d .svg {
    height: calc(100vw - 100px);
  }
}
@supports (-ms-ime-align: auto) {
  .main .region-content .ticket-process-panel .court-map_2d {
    /* IE Edge 16+ CSS */
  }
  .main .region-content .ticket-process-panel .court-map_2d .svg {
    height: calc(100vw - 100px);
  }
}
.main .region-content .ticket-process-panel .court-map_2d g.platinum > path, .main .region-content .ticket-process-panel .court-map_2d g.platinum > polygon, .main .region-content .ticket-process-panel .court-map_2d g.platinum > rect {
  @apply fill-current text-green-600;
}
.main .region-content .ticket-process-panel .court-map_2d g.gold > path, .main .region-content .ticket-process-panel .court-map_2d g.gold > polygon, .main .region-content .ticket-process-panel .court-map_2d g.gold > rect {
  @apply fill-current text-yellow-400;
}
.main .region-content .ticket-process-panel .court-map_2d g.silver > path, .main .region-content .ticket-process-panel .court-map_2d g.silver > polygon, .main .region-content .ticket-process-panel .court-map_2d g.silver > rect {
  @apply fill-current text-yellow-600;
}
.main .region-content .ticket-process-panel .court-map_2d g.top > path, .main .region-content .ticket-process-panel .court-map_2d g.top > polygon, .main .region-content .ticket-process-panel .court-map_2d g.top > rect {
  @apply fill-current text-red-600;
}
.main .region-content .ticket-process-panel .court-map_2d g.premium > path, .main .region-content .ticket-process-panel .court-map_2d g.premium > polygon, .main .region-content .ticket-process-panel .court-map_2d g.premium > rect {
  @apply fill-current text-purple-600;
}
.main .region-content .ticket-process-panel .court-map_2d g.accessible > path, .main .region-content .ticket-process-panel .court-map_2d g.accessible > polygon, .main .region-content .ticket-process-panel .court-map_2d g.accessible > rect {
  @apply fill-current text-blue-600;
}
.main .region-content .ticket-process-panel .court-map_2d .svg-legend rect {
  stroke-width: 2px;
  fill: #ddd;
  @apply text-black stroke-current;
}
.main .region-content .ticket-process-panel .court-map_2d .svg-legend polygon.available {
  opacity: 0.5;
}
.main .region-content .ticket-process-panel .court-map_2d .svg-text {
  text-transform: uppercase;
  font-size: 3em;
  letter-spacing: 0.1em;
  @apply text-black fill-current;
}
.main .region-content .ticket-process-panel .court-map_2d .svg-text_vertical {
  writing-mode: tb;
  writing-mode: vertical-rl;
  text-orientation: upright;
  letter-spacing: 0;
}
.main .region-content .ticket-process-panel .court-map_2d .svg-settore {
  opacity: 0;
}
.main .region-content .ticket-process-panel .court-map_2d .svg-settore > path, .main .region-content .ticket-process-panel .court-map_2d .svg-settore > polygon, .main .region-content .ticket-process-panel .court-map_2d .svg-settore > rect {
  stroke: white;
  stroke-width: 2px;
}
.main .region-content .ticket-process-panel .court-map_2d .svg-settore .svg-text {
  pointer-events: none;
  opacity: 0;
}
.main .region-content .ticket-process-panel .court-map_2d .svg-settore[data-available] {
  opacity: 0.5;
  cursor: pointer;
}
.main .region-content .ticket-process-panel .box-scelta-posto .sedia .sedia_seduta {
  fill: rgba(255, 255, 255, 0.15);
}
.main .region-content .ticket-process-panel .box-scelta-posto .sedia_libera,
.main .region-content .ticket-process-panel .box-scelta-posto .sedia_riservata,
.main .region-content .ticket-process-panel .box-scelta-posto .sedia_altro,
.main .region-content .ticket-process-panel .box-scelta-posto .sedia_noassign,
.main .region-content .ticket-process-panel .box-scelta-posto .sedia_prenotata {
  cursor: pointer;
}
.main .region-content .ticket-process-panel .box-scelta-posto .sedia_libera:hover .sedia__schienale,
.main .region-content .ticket-process-panel .box-scelta-posto .sedia_riservata:hover .sedia__schienale,
.main .region-content .ticket-process-panel .box-scelta-posto .sedia_altro:hover .sedia__schienale,
.main .region-content .ticket-process-panel .box-scelta-posto .sedia_noassign:hover .sedia__schienale,
.main .region-content .ticket-process-panel .box-scelta-posto .sedia_prenotata:hover .sedia__schienale {
  fill: #efc700;
}

#sliding-popup {
  @apply bg-black bg-opacity-75 text-white w-full fixed inset-x-0 p-4 z-50;
}

.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
}

.blockUI {
  z-index: 1011;
  position: fixed;
  padding: 10px;
  margin: 0;
  width: auto;
  top: 40%;
  left: 20%;
  text-align: left;
  color: #008e61;
  cursor: wait;
}
@screen sm {
  .blockUI.blockMsg {
    left: 35%;
  }
}
@screen md {
  .blockUI.blockMsg {
    left: 37%;
  }
}
@screen lg {
  .blockUI.blockMsg {
    left: 41%;
  }
}
@screen xl {
  .blockUI.blockMsg {
    left: 43%;
  }
}
.blockUI h1 {
  margin: 0;
  color: #fff;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0;
  font-weight: inherit;
}
.blockUI h1::before {
  content: "";
  float: left;
  width: 40px;
  height: 41px;
  margin: -8px 10px 0 0;
  background: url("../img/oval-green.svg") no-repeat top center;
}
.blockUI.blockOverlay {
  z-index: 1000;
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.8;
  cursor: wait;
  position: fixed;
}

.wf-loading {
  visibility: hidden;
}

.button-operator {
  visibility: hidden;
}

.ordini-profilo {
  margin: 30px 0;
  background-color: #efefef;
  padding: 10px;
}

.ordini-profilo h2 {
  margin-top: 0;
}

.order-detail-info {
  padding: 0 20px 20px;
}

#payment-details {
  margin-bottom: 15px;
}

#selTariffeMigliori {
  text-align: center;
  min-height: 42px;
  font-size: 18px;
}

div#eventi-ground div div.mappa div div div#map-container {
  display: none;
}

div.cart-empty-page a {
  background: #da322a;
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  margin-top: 20px;
  padding: 10px;
  font-style: normal;
  box-shadow: 0 3px 4px rgba(34, 32, 35, 0.5);
}

.banner--small {
  height: 380px;
}

.banner--small__content {
  padding-right: 10px;
}

.list-partner img {
  width: 100px;
}

figure.effect-mgquadro h2 {
  padding: 10% 2%;
}

figure.effect-mgquadro p {
  padding: 0 10px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-number {
  @apply text-gray-900 transition duration-150 ease-in-out;
}
.btn-number:hover {
  @apply text-primary;
}
.btn-number:focus {
  @apply text-primary outline-none;
}
.btn-number:disabled {
  @apply text-gray-400;
}
.btn-number:disabled:hover, .btn-number:disabled:focus {
  @apply text-gray-400;
}

.commerce-line-item-views-form .commerce-line-item-actions {
  @apply flex flex-wrap text-center items-center justify-center;
}

.view-commerce-cart-form tr,
.view-commerce-cart-summary tr {
  @apply align-middle;
}

.checkout-review {
  @apply w-full;
}