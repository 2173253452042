// @layer base { .button-operator purged
body {
  @apply bg-white;

  font-family: "Barlow", sans-serif;
}

//gabriele
.bigBkg {
  position: absolute;
  width: 100%;
  height: 100%;
  span {
    @apply text-black text-center text-5xl max-w-md mx-auto block -mt-64 bg-secondary py-4 px-8 mb-48 z-20;

    font-family: "Barlow", sans-serif;
  }
}
.logoHeader  {
  background: #000;
  border: 1px solid #000;
  @apply w-40;
}

.bg-cover {
  // position: relative;

  // ::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   @apply w-8 bg-primary;
  //   height: 40vh;
  // }
  // ::after {
  //   content: "";
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   @apply w-8 bg-secondary;
  //   height: 40vh;
  // }
}

.hero-home {
  position: relative;
  z-index: 10;
}

.boxHome-01 {
  background: #02081f url("../img/box-home/01.jpg") no-repeat center;
  // background: #02081F url("../img/homebox-biglietti.jpg") no-repeat center;
  background-size: cover;
  -webkit-transition: background-image 0.6s ease-in-out;
  transition: background-image 0.6s ease-in-out;
  &:hover {
    background: #02081f url("../img/box-home/01_hover.jpg") no-repeat center;
    background-size: cover;
    -webkit-transition: background-image 0.6s ease-in-out;
    transition: background-image 0.6s ease-in-out;
  }
}

.boxHome-02 {
  background: #02081f url("../img/box-home/02.jpg") no-repeat center;
  // background: #02081F url("../img/homebox-biglietti.jpg") no-repeat center;
  background-size: cover;
  -webkit-transition: background-image 0.6s ease-in-out;
  transition: background-image 0.6s ease-in-out;
  &:hover {
    background: #02081f url("../img/box-home/02_hover.jpg") no-repeat center;
    background-size: cover;
    -webkit-transition: background-image 0.6s ease-in-out;
    transition: background-image 0.6s ease-in-out;
  }
}

.boxHome-03 {
  background: #02081f url("../img/box-home/03.jpg") no-repeat center;
  // background: #02081F url("../img/homebox-biglietti.jpg") no-repeat center;
  background-size: cover;
  -webkit-transition: background-image 0.6s ease-in-out;
  transition: background-image 0.6s ease-in-out;
  position: relative;
  &:hover {
    background: #02081f url("../img/box-home/03_hoverCS2.jpg") no-repeat center;
    background-size: cover;
    -webkit-transition: background-image 0.6s ease-in-out;
    transition: background-image 0.6s ease-in-out;
    &::after {
      // position: absolute;
      // display: block;
      // content: "";
      // width: 130px;
      // height: 100px;
      // background: url("../img/coming-soon-3.svg") no-repeat bottom center;
      // background-size: 130px;
      // bottom: 20px;
      // -webkit-transition: background-image 0.6s ease-in-out;
      // transition: background-image 0.6s ease-in-out;

      // @screen sm {
      //   background-size: 120px;
      //   top: 10px;
      // }
      // @screen md {
      //   background-size: 80px;
      //   top: 0px;
      // }
      // @screen lg {
      //   background-size: 120px;
      //   top: 10px;
      // }
    }
  }
}

//STILIZZAZIONE MENU

#menu {
  background: #051224;
  //font-style: italic;
  color: #fff;

  .mm-navbar .mm-title {
    visibility: hidden;
  }

  .mm-listview {
    li {
      font-size: 20px;
      @apply border-b border-gray-700 border-r-0;

      a {
        @apply text-secondary pl-6;

        line-height: 30px;
      }

      span {
        color: #fff;
        line-height: 30px;
      }
    }
  }
}

.mm-slideout {
  z-index: auto;
}

.nav-top {
  &__menu {
    li {
    //   &:last-child {
    //     @apply border-0 bg-secondary;
    //     a {
    //       @apply border-0 bg-secondary text-white;
    //       &:hover {
    //         @apply text-gray-300;
    //       }
    //     }
    //   }
      &:last-child {
        @apply border-0;
      }

      a {
        @apply px-1 mx-1 text-primary transition-colors duration-200 font-bold;

        &:hover {
          @apply text-secondary no-underline;
        }
      }
    }
  }
}

// #main-menu {
//   li:last-child {
//     background: #f91126;
//     color: white !important;
//     &:hover {
//       color: #efefef !important;
//     }
//   }
// }

.main {
  .breadcrumb {
    background-color: transparent;
    text-transform: uppercase;
    padding: 15px 10px 5px 10px;
    font-style: italic;
    border-bottom: solid 1px #ddd;
    border-radius: 0;
    margin-bottom: 10px;
    list-style: none;

    li {
      display: inline-block;
      color: red;

      a {
        color: inherit;
      }
    }

    & > li + li::before {
      padding: 0 5px;
      color: #ccc;
      content: "/\00a0";
    }

    .last {
      color: #a29da5;
    }
  }

  .region-content {
    // position: relative;

    // ::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   @apply w-8 bg-primary;
    //   height: 40vh;
    // }
    // ::after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    //   @apply w-8 bg-secondary;
    //   height: 40vh;
    // }
    min-height: 300px;

    .ticket-process-panel {
      .court__message {
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        font-weight: bold;
      }

      .court-map_2d {
        margin: 1em 0;

        .svg {
          max-width: 100%;
          max-height: 600px;
          margin: auto;
          display: block;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ CSS */
          .svg {
            height: calc(100vw - 100px);
          }
        }

        @supports (-ms-ime-align: auto) {
          /* IE Edge 16+ CSS */
          .svg {
            height: calc(100vw - 100px);
          }
        }

        // .svg-circle {
        //   stroke: black;
        //   fill: white;
        //   stroke-width: 3px;
        // }

        g {
          &.platinum {
            & > path,
            & > polygon,
            & > rect {
              @apply fill-current text-green-600;
            }
          }

          &.gold {
            & > path,
            & > polygon,
            & > rect {
              @apply fill-current text-yellow-400;
            }
          }

          &.silver {
            & > path,
            & > polygon,
            & > rect {
              @apply fill-current text-yellow-600;
            }
          }

          &.top {
            & > path,
            & > polygon,
            & > rect {
              @apply fill-current text-red-600;
            }
          }

          &.premium {
            & > path,
            & > polygon,
            & > rect {
              @apply fill-current text-purple-600;
            }
          }

          &.accessible {
            & > path,
            & > polygon,
            & > rect {
              @apply fill-current text-blue-600;
            }
          }
        }

        .svg-legend {
          rect {
            stroke-width: 2px;
            fill: #ddd;
            @apply text-black stroke-current;
          }
          polygon {
            &.available {
              opacity: 0.5;
            }
          }
        }

        .svg-text {
          text-transform: uppercase;
          font-size: 3em;
          letter-spacing: 0.1em;

          @apply text-black fill-current;

          // text {
          //   font-weight: bold;
          // }

          &_vertical {
            writing-mode: tb;
            writing-mode: vertical-rl;
            text-orientation: upright;
            letter-spacing: 0;
          }
        }

        .svg-settore {
          opacity: 0;

          & > path,
          & > polygon,
          & > rect {
            stroke: white;
            stroke-width: 2px;
          }

          .svg-text {
            pointer-events: none;
            opacity: 0;
          }

          &[data-available] {
            opacity: 0.5;
            cursor: pointer;
          }
        }
      }

      /* Scelta in pianta */
      .box-scelta-posto {
        .sedia .sedia_seduta {
          fill: rgba(255, 255, 255, 0.15);
        }

        .sedia_libera,
        .sedia_riservata,
        .sedia_altro,
        .sedia_noassign,
        .sedia_prenotata {
          cursor: pointer;
        }

        .sedia_libera:hover .sedia__schienale,
        .sedia_riservata:hover .sedia__schienale,
        .sedia_altro:hover .sedia__schienale,
        .sedia_noassign:hover .sedia__schienale,
        .sedia_prenotata:hover .sedia__schienale {
          fill: #efc700;
        }
      }
    }
  }
}

#sliding-popup {
  @apply bg-black bg-opacity-75 text-white w-full fixed inset-x-0 p-4 z-50;
}

.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
}

.blockUI {
  z-index: 1011;
  position: fixed;
  padding: 10px;
  margin: 0;
  width: auto;
  top: 40%;
  left: 20%;
  text-align: left;
  color: #008e61;
  cursor: wait;

  &.blockMsg {
    @screen sm {
      left: 35%;
    }

    @screen md {
      left: 37%;
    }

    @screen lg {
      left: 41%;
    }

    @screen xl {
      left: 43%;
    }
  }

  h1 {
    margin: 0;
    color: #fff;
    font-size: 21px;
    font-style: italic;
    letter-spacing: 0;
    font-weight: inherit;

    &::before {
      content: "";
      float: left;
      width: 40px;
      height: 41px;
      margin: -8px 10px 0 0;
      background: url("../img/oval-green.svg") no-repeat top center;
    }
  }

  &.blockOverlay {
    z-index: 1000;
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.8;
    cursor: wait;
    position: fixed;
  }
}

// Ex template

.wf-loading {
  visibility: hidden;
}

.button-operator {
  visibility: hidden;
}

.ordini-profilo {
  margin: 30px 0;
  background-color: #efefef;
  padding: 10px;
}

.ordini-profilo h2 {
  margin-top: 0;
}

.order-detail-info {
  padding: 0 20px 20px;
}

#payment-details {
  margin-bottom: 15px;
}

#selTariffeMigliori {
  text-align: center;
  min-height: 42px;
  font-size: 18px;
}

div#eventi-ground div div.mappa div div div#map-container {
  display: none;
}

div.cart-empty-page a {
  background: #da322a;
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  margin-top: 20px;
  padding: 10px;
  font-style: normal;
  box-shadow: 0 3px 4px rgba(34, 32, 35, 0.5);
}

.banner--small {
  height: 380px;
}

.banner--small__content {
  padding-right: 10px;
}

.list-partner img {
  width: 100px;
}

figure.effect-mgquadro h2 {
  padding: 10% 2%;
}

figure.effect-mgquadro p {
  padding: 0 10px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-number {
  @apply text-gray-900 transition duration-150 ease-in-out;
  &:hover {
    @apply text-primary;
  }
  &:focus {
    @apply text-primary outline-none;
  }
  &:disabled {
    @apply text-gray-400;
    &:hover,
    &:focus {
      @apply text-gray-400;
    }
  }
}

.commerce-line-item-views-form .commerce-line-item-actions {
  @apply flex flex-wrap text-center items-center justify-center;
}

// /sites/all/modules/commerce/modules/cart/theme/commerce_cart.theme.css
.view-commerce-cart-form tr,
.view-commerce-cart-summary tr {
  @apply align-middle;
}

.checkout-review {
  @apply w-full;
}
// }
